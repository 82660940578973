import React, { useState } from 'react'
import './style.css'

function Post({title, username, content, postKey, rating}) {
    const [postRating, setPostRating] = useState(rating);

    // Creating vote request
    const vote = async (type) => {
        const resp = await fetch(
            `https://test-worker.vvoziyan.workers.dev/vote?key=${postKey}&type=${type}`, {
            method: 'GET',
            redirect: 'follow',
            cache: 'no-store',
        }).then(async (result) => {
            try {
                const data = await result.json();
                if (data.current_rating) {
                    setPostRating(data.current_rating);
                }
            }
            catch {
                
            }
        }).catch(error => {
        });
    }

    return (
        <div className="post">
            <p className="post__username">Posted by. <span>{username}</span></p>
            <h2 className="post__title" aria-label="">{title}</h2>
            <p className="post__content">{content}</p>
            <div className="post__rating">
                <button className="post__rating__button upvote" onClick={() => vote('upvote')}>Upvote</button>
                <span>{postRating}</span>
                <button className="post__rating__button downvote" onClick={() => vote('downvote')}>Downvote</button>
            </div>
        </div>
    )
}

export default Post