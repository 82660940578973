
import React, { useState, useEffect } from 'react'
import Post from '../Post';
import ModalLoading from '../ModalLoading';
import './style.css'

function Feed() {
    const [posts, setPosts] = useState([]);

    const [showModal, setModal] = useState(false);

    // Retrieving posts
    useEffect(() => {
        const getPosts = async () => {
            setModal(true);
            const resp = await fetch(
                "https://test-worker.vvoziyan.workers.dev/posts", {
                method: 'GET',
                redirect: 'follow',
                cache: 'no-store',
                headers: {
                    "Accept": "application/json"
                }
            }
            ).catch(error => {
            });
            const postsResp = await resp.json();
            console.log(postsResp)
            setPosts(postsResp.posts);
            setModal(false);

        };

        getPosts();
    }, []);

    // Creating array of Post blocks
    const postsItems = [];
    for (const [index, item] of posts.entries()) {
        postsItems.push(<Post title={item.title} username={item.username} content={item.content} postKey={item.key} key={index} rating={item.rating}></Post>)
    }

    return (
        <>
            {showModal ? <ModalLoading /> : null}
            <div className="feed">
                {postsItems}
            </div>
        </>
    );
}

export default Feed;