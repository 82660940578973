import './App.css';
import Feed from './components/Feed/index';
import Header from './components/Header/index';
import PostCreationForm from './components/PostCreationForm/index';

function App() {
  return (
    <div className="App">
      <Header />
      <main className="main_container">
      <Feed></Feed>
      <div className="test">
        <PostCreationForm />
      </div>
      </main>
    </div>
  );
}

export default App;
