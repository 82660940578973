import React, { useState } from 'react'
import './style.css'

function moveToPosts() {
    const main = document.querySelector('.main_container');
    main.className = "main_container";
}

function moveToCreatePosts() {
    const main = document.querySelector('.main_container');
    main.className = "main_container moved";
}

function Header() {

    return (
        <header>
            <nav className="navigation">
                <ul className="navigation__list">
                    <li className="navigation__item">
                        <button className="navigation__button active" onClick={moveToPosts}>Posts</button>
                    </li>
                    <li className="navigation__item">
                        <button className="navigation__button" onClick={moveToCreatePosts}>Create post</button>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Header