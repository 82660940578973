
// eslint-disable-next-line
import React from 'react'
import './style.css'

function ModalLoading({show}) {
    
    return (
        <div className="modal_loading">
            <div className="modal__bars">
                <div className="modal__bar">
            {show}</div>
                <div className="modal__bar"></div>
                <div className="modal__bar"></div>
                <div className="modal__bar"></div>
                <div className="modal__bar"></div>
            </div>
        </div>
    );
}

export default ModalLoading;