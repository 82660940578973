import React, { useState, useEffect } from 'react'
import ModalLoading from '../ModalLoading';
import './style.css'

function PostCreationForm(props) {
    const [showModal, setModal] = useState(false);
    const [formMessage, setFormMessage] = useState('')
    const [formMessageType, setFormMessageType] = useState('')
    
    const [username, setUsername] = useState([]);
    const [title, setTitle] = useState([]);
    const [content, setContent] = useState([]);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value)
    }
    const handleTitleChange = (e) => {
        setTitle(e.target.value)
    }
    const handleContentChange = (e) => {
        setContent(e.target.value)
    }

    const createPost = async (postData) => {
        setModal(true);
        console.log(JSON.stringify(postData));
        const resp = await fetch(
            "https://test-worker.vvoziyan.workers.dev/posts", {
            method: 'POST',
            redirect: 'follow',
            cache: 'no-store',
            body: JSON.stringify(postData),
            headers: {
                "Accept": "application/json"
            }
        }).then((result) => {
            setFormMessage('Post submited');
            setFormMessageType('success');
        }).catch(error => {
            setFormMessage('Something went wrong');
            setFormMessageType('warning');
        });
        setModal(false);
    }

    const handleSubmit = e => {
        e.preventDefault();

        let isValidated = true;
        if (username.length === 0) {
            document.querySelector(".input__username").className = 'input__username warning'
            isValidated = false;
        }
        else {
            document.querySelector(".input__username").className = 'input__username'
        }
        if (title.length === 0) {
            document.querySelector(".input__title").className = 'input__title warning'
            isValidated = false;
        }
        else {
            document.querySelector(".input__title").className = 'input__title'
        }
        if (content.length === 0) {
            document.querySelector(".input__content").className = 'input__content warning'
            isValidated = false;
        }
        else {
            document.querySelector(".input__content").className = 'input__content'
        }

        if (isValidated) { 
            createPost({
                'username': username,
                'title': title,
                'content': content
            })
        }
        else {
        setFormMessage('Fill all required fields');
        setFormMessageType('warning');
        }
    }

    return (
        <>
        {showModal ? <ModalLoading /> : null}
        <section className="form_section" onSubmit={handleSubmit}>
            <h1>Create post</h1>
            <form>
                <label>
                    Username
                    <input className="input__username" value={username} type="text" onChange={handleUsernameChange}/>
                </label>
                <label>
                    Title
                    <input className="input__title" value={title} onChange={handleTitleChange}/>
                </label>
                <label>
                    Content
                    <textarea className="input__content" value={content} onChange={handleContentChange}/>
                </label>
                <button >Submit</button>
            </form>
            {formMessage.length !== 0 ? <p className={`form__message ${formMessageType}`}>{formMessage}</p> : null}
        </section>
        </>
    )
}

export default PostCreationForm